import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { RichText } from 'prismic-reactjs';

import Seo from '@layouts/common/seo';
import Layout from '@layouts/index';
import LeftArrowContact from '@assets/contact/left-arrow';
import ContactComponent from '@components/contact-component/contact-component';

export default function TermsAndConditionsPage() {
  const data = useStaticQuery(graphql`
    query TermsAndConditions {
      prismicTermsAndConditions {
        data {
          terms_and_conditions {
            raw
          }
        }
        uid
      }
    }
  `);

  return (
    <Layout>
      <Seo title="terms and conditions" />
      <div className="termsandconditions-container common-container u-margin-top-xl u-margin-bottom-md">
        <ul className="privacy-policy-breadcrumb">
          <li>Home</li>
          <LeftArrowContact />
          <li>Terms And Conditions</li>
        </ul>
        <div className="tac-left">
          <div className="terms-and-conditions-holder ">
            <h1>Terms and conditions</h1>
            <div className="tac-richtext">
              {RichText.render(data.prismicTermsAndConditions.data.terms_and_conditions.raw)}
            </div>
          </div>
          <ContactComponent />
          <div className="privacy-policy-background-container"></div>
        </div>

        <div className="tac-right"></div>
      </div>
    </Layout>
  );
}

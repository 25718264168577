import React from 'react';
import TermsAndConditionsPage from '../templates/terms-and-conditions-page/terms-and-conditions';

export default function TermsAndConditions() {
  return (
    <div>
      <TermsAndConditionsPage />
    </div>
  );
}
